import { create } from 'zustand'
import {
  CameraDetailsDTO,
  DeployActionEnum,
  DeploymentDTO,
  DeviceStatusEnum,
  SoftwareNameEnum,
} from 'api/api_code'

import { persist, createJSONStorage, devtools } from 'zustand/middleware'
import { CameraLicense } from 'types/global'
import { getCameraLicense } from '../components/camera/helpers/camera'

type CameraAction = {
  _reset: () => void
  getLicenseName: () => string
  isBusy: () => boolean
  isReady: () => boolean
}

const initialState: CameraDetailsDTO = {
  uuid: '',
  name: '',
  software_installed: [],
  software_installing: [],
  status: 'offline',
  producer: { serial_number: '', license_ticket_id: '', license_type: '', hostname: '' },
  fields: [{ uuid: '', name: '', timezone: '' }],
  framerate: null,
  lens_size: null,
  organization: { name: '' },
}

const cameraPersistOpts = {
  name: 'camera-store',
  storage: createJSONStorage(() => sessionStorage),
  // Save camera lens type for calibration logic
  partialize: (
    state: CameraDetailsDTO & CameraAction
  ): Partial<CameraDetailsDTO & CameraAction> => ({
    lens_size: state.lens_size,
    producer: state.producer,
  }),
}

const injectMiddlwares = (f) => devtools(persist(f, cameraPersistOpts))

const useCameraStore = create<CameraDetailsDTO & CameraAction>()(
  injectMiddlwares((set, get) => ({
    ...initialState,
    _reset: () => set(initialState),
    getLicenseName: () => getCameraLicense(get().producer.license_type),
    isBusy: () => {
      const cameraStatus = getCurrentCameraStatus(get().status, softwareStatusSelector(get()))
      return (
        (!cameraStatus.isReady && !cameraStatus.isNotConfigured) ||
        cameraStatus.isUpgrading ||
        cameraStatus.isInstalling ||
        cameraStatus.isRecording
      )
    },
    isReady: () => get().status === DeviceStatusEnum.Ready,
  }))
)

const softwareStatusSelector = (state: CameraDetailsDTO) =>
  state.software_installing?.find(
    (item) => item.version?.software?.name === SoftwareNameEnum.Automatictv
  )?.action

const isXSSelector = (state: CameraDetailsDTO) => state.lens_size === 'XS'

const isProBroadcastSelector = (state: CameraDetailsDTO) => {
  const license = state.producer.license_type
  return license !== CameraLicense.ATV_ACE && license !== CameraLicense.ATV_ONE && license !== ''
}

const isOneSelector = (state: CameraDetailsDTO) => {
  const license = state.producer.license_type
  return license === CameraLicense.ATV_ONE
}

const getCurrentCameraStatus = (
  cameraStatus: CameraDetailsDTO['status'],
  softwareStatus: DeploymentDTO['action']
) => {
  return {
    isReady: cameraStatus === DeviceStatusEnum.Ready,
    isOffline: cameraStatus === DeviceStatusEnum.Offline,
    isNotConfigured: cameraStatus === DeviceStatusEnum.NotConfigured,
    isUpgrading: softwareStatus === DeployActionEnum.Upgrade,
    isInstalling: softwareStatus === DeployActionEnum.Install,
    isRecording: cameraStatus === DeviceStatusEnum.Recording,
  }
}

export {
  useCameraStore,
  isXSSelector,
  isProBroadcastSelector,
  isOneSelector,
  softwareStatusSelector,
}
