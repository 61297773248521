import { CameraLicense } from 'types/global'

export const oneCameras = {
  LEFT_CAMERA: 'camera_left',
  CENTER_LEFT_CAMERA: 'camera_centerleft',
  CENTER_RIGHT_CAMERA: 'camera_centerright',
  RIGHT_CAMERA: 'camera_right',
}

export const cameraLicenses = {
  [CameraLicense.PRO_BASIC]: 'ATV Pro Basic',
  [CameraLicense.PRO_ADVANCED]: 'ATV Pro Advanced',
  [CameraLicense.BROADCAST_BASIC]: 'ATV Broadcast Basic',
  [CameraLicense.BROADCAST_ADVANCED]: 'ATV Broadcast Advanced',
  [CameraLicense.ATV_ONE]: 'ATV One',
  [CameraLicense.ATV_ACE]: 'ATV Ace',
}
